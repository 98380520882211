<template>
  <div class="connectus">
    <div class="contanier">
      <el-row :gutter="20">
        <el-col :span="12"
          ><div class="fangshi">
            <h2>联系我们</h2>
            <p>给我们发送电子邮件： {{ email.value }}</p>
          </div></el-col
        >
        <el-col :span="12"
          ><div class="img">
            <el-image
              style="width: 500px; height: 390px"
              :src="require('@/util/images/index/pic_contact.png')"
              fit="contain"
            ></el-image></div
        ></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { index } from "../../api/userapi";
export default {
  name: "connectus",
  data() {
    return { email: "" };
  },

  mounted() {
    this.getinfo();
  },

  methods: {
    getinfo() {
      index()
        .then(res => {
          this.email = res.config;
        })
        .catch();
    }
  }
};
</script>

<style lang="less" scoped>
.fangshi {
  padding-top: 110px;
  text-align: center;
  color: #333333;
  h2 {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 30px;
  }
  p {
    font-size: 18px;
    line-height: 24px;
  }
}
.img {
  text-align: right;
}
.connectus {
  background-color: #f0f2f5;
  height: 700px;
}
.contanier {
  padding: 100px 0 200px 0;
}
</style>
